import React, { useContext } from 'react';
import output from './consumers/console.log';
import gaDatalayer from './consumers/ga.datalayer';
import sas360 from './consumers/sas360';
import LoggerContext from './LoggerContext';
import fb from './consumers/facebook';
import kibana from './consumers/kibana';

const TrackingSubscriber = (props) => {
    var sas = {};
    if (window.com_sas_ci_acs) {
        try {
            sas = {
                session: window.com_sas_ci_acs.ob_configure.getSessionId(),
                visitor: window.com_sas_ci_acs.ob_configure.getVisitorId()
            };
        } catch (err) {}
    }
    const log = useContext(LoggerContext);
    props.subscribe.map((event) =>
        props.eventBus.subscribe(event, (payload) => {
            var consumers = findConsumers(payload, props);
            try {
                if (consumers.console) output(payload);
            } catch (err) {
                log.error(
                    JSON.stringify({ error: { msg: err.message, stack: err.stack, area: 'console' }, payload, sas }),
                    'TrackingSubscriber'
                );
            }
            try {
                if (consumers.ga) {
                    if (
                        window.CookieInformation &&
                        window.CookieInformation.getConsentGivenFor('cookie_cat_statistic')
                    ) {
                        gaDatalayer(payload);
                    }
                }
            } catch (err) {
                log.error(
                    JSON.stringify({ error: { msg: err.message, stack: err.stack, area: 'ga' }, payload, sas }),
                    'TrackingSubscriber'
                );
            }
            try {
                if (consumers.sas360) {
                    if (
                        window.CookieInformation &&
                        window.CookieInformation.getConsentGivenFor('cookie_cat_marketing')
                    ) {
                        sas360(payload, log);
                    }
                }
            } catch (err) {
                log.error(
                    JSON.stringify({ error: { msg: err.message, stack: err.stack, area: 'sas' }, payload, sas }),
                    'TrackingSubscriber'
                );
            }
            try {
                if (props.consumer.includes('kibana')) kibana(payload, log);
            } catch (err) {
                log.error(
                    JSON.stringify({ error: { msg: err.message, stack: err.stack, area: 'kibana' }, payload, sas }),
                    'TrackingSubscriber'
                );
            }
            try {
                if (consumers.fb) {
                    if (
                        window.CookieInformation &&
                        window.CookieInformation.getConsentGivenFor('cookie_cat_marketing')
                    ) {
                        fb(payload);
                    }
                }
            } catch (err) {
                log.error(
                    JSON.stringify({ error: { msg: err.message, stack: err.stack, area: 'fb' }, payload, sas }),
                    'TrackingSubscriber'
                );
            }
        })
    );

    return <></>;
};

const findConsumers = (payload, props) => {
    var consumers = {
        console: false,
        ga: false,
        sas360: false,
        kibana: false,
        fb: false
    };
    if (payload.hasOwnProperty('consumerOverwrite')) {
        // Decide which consumers to send trackingdata to.
        const consumerOverwrite = payload.consumerOverwrite;

        if (Object.values(consumerOverwrite).toString().includes('console')) {
            consumers.console = true;
        }
        if (Object.values(consumerOverwrite).toString().includes('ga')) {
            consumers.ga = true;
        }
        if (Object.values(consumerOverwrite).toString().includes('sas360')) {
            consumers.sas360 = true;
        }
        if (Object.values(consumerOverwrite).toString().includes('kibana')) {
            consumers.kibana = true;
        }
        if (Object.values(consumerOverwrite).toString().includes('fb')) {
            consumers.fb = true;
        }
    }
    // Default to config if consumerOverwrite is empty
    else {
        consumers = {
            console: props.consumer.includes('console'),
            ga: props.consumer.includes('ga'),
            sas360: props.consumer.includes('sas360'),
            kibana: props.consumer.includes('kibana'),
            fb: props.consumer.includes('fb')
        };
    }

    return consumers;
};

export { findConsumers };
export default TrackingSubscriber;
