import * as Cookies from 'js-cookie';
import getKeyValue, { setKeyValue } from './getKeyValue';

const enritch = (data) => {
    if (!('url' in data)) data['url'] = window.location.href;

    if (window.CookieInformation && window.CookieInformation.getConsentGivenFor('cookie_cat_statistic')) {
        // fetch unique visitor id
        data['tdkVisitor'] = Cookies.get('tdkVisitor');
        if (!data['tdkVisitor']) data['tdkVisitor'] = makeid(30);
        const host = window.location.host.split('.');
        Cookies.set('tdkVisitor', data['tdkVisitor'], {
            expires: 365,
            path: '/',
            domain: '.' + host[host.length - 2] + '.' + host[host.length - 1]
        });

        // fetch unique session id
        data['userSession'] = Cookies.get('tdkSession');
        if (!data['userSession']) {
            data['userSession'] = makeid(30);
            Cookies.set('tdkSession', data['userSession'], {
                path: '/',
                domain: '.' + host[host.length - 2] + '.' + host[host.length - 1]
            });
        }
    } else {
        data['userSession'] = null; // ensure we use our own usersession - not the one from hippo
    }

    if (data.action === 'app-navigate') {
        const vurl = getKeyValue(data.items, 'route');
        const surl = sanitizeVirtualUrl(vurl);
        setKeyValue(data.items, 'route', surl);
    }

    return data;
};

export const makeid = (length) => {
    const result = [];
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < length; i++) {
        result.push(characters.charAt(Math.floor(Math.random() * characters.length)));
    }
    return result.join('');
};

const reparseUrl = (input, absolute = true) => {
    const url = new URL(input);
    if (absolute) {
        return (
            url.protocol +
            '//' +
            url.host +
            url.pathname +
            (url.pathname.substr(-1) === '/' ? '' : '/') +
            url.search +
            url.hash
        );
    } else {
        return url.pathname.substring(1) + (url.pathname.substr(-1) === '/' ? '' : '/') + url.search + url.hash;
    }
};

export const sanitizeVirtualUrl = (input) => {
    let output = '';

    // absolute url
    if (input.indexOf('://') > 0) {
        output = reparseUrl(input, true);
    }
    // relative url starting at root
    else if (input.substr(0, 1) === '/') {
        output = reparseUrl('http://tdk.dk' + input, false);
    }
    // relative url without root
    else if (input.substr(0, 1) !== '/') {
        output = reparseUrl('http://tdk.dk/' + input, false);
    }

    return output;
};

export default enritch;
