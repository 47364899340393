import camelCase from 'camelcase';
import enritch from '../utils/enritchDepricated';
import getKeyValue from '../utils/getKeyValue';
import getPrice from '../utils/getPrice';
import { getRandomString } from '../utils/sessionId';

const gaDatalayer = (data) => {
    // validate
    if (!validate(data)) {
        throw new Error('could not validate data');
    }

    const enritchedData = enritch(data);

    // transform
    const ga = transform(enritchedData);

    // consume
    if (window.dataLayer && ga) window.dataLayer.push(ga);
};

const validate = (data) => {
    if (typeof data !== 'object') return false;
    if (typeof data.appName === 'undefined') return false;
    if (typeof data.action === 'undefined') return false;
    return true;
};

const transform = (data) => {
    data.brand =
        window.topContext.config.getSiteConfig().brandAbbrev.toLocaleLowerCase() === 'co'.toLocaleLowerCase()
            ? 'Coopforsikringer'
            : 'Topdanmark';

    if (data.action === 'app-navigate') return transformVirtualPageview(data);
    if (data.action === 'app-ecommerce-view') return transformViewProduct(data);
    if (data.action === 'app-ecommerce-cart') return transformCartProduct(data);
    if (data.action === 'app-ecommerce-checkout') return transformCheckout(data);
    if (data.action === 'app-ecommerce-purchase') return transformCheckout(data);
    if (data.action === 'app-form') return false;

    return transformCustomEvent(data);
};

const transformCheckout = (data) => {
    const products = getKeyValue(data.items, 'products');
    const step = getKeyValue(data.items, 'step');
    let revenue = 0,
        action = null;
    if (data.action === 'app-ecommerce-purchase') action = 'purchase';
    if (data.action === 'app-ecommerce-checkout') action = 'checkout';

    const productList = products.map((product) => {
        const coverages = !product.coverages
            ? ''
            : product.coverages
                  .map((c) => c.name)
                  .join('|')
                  .replace(/-+$/, '');
        revenue += getPrice(product.price);
        return {
            brand: data.brand,
            category: product.category,
            id: product.id,
            name: product.name,
            price: getPrice(product.price),
            variant: coverages,
            quantity: 1
        };
    });

    const ga = {
        event: action,
        ecommerce: {
            currencyCode: 'DKK'
        }
    };

    ga.ecommerce[`${action}`] = {
        products: productList
    };

    if (data.action === 'app-ecommerce-checkout') ga.ecommerce[`${action}`]['actionField'] = { step: step };

    if (data.action === 'app-ecommerce-purchase')
        ga.ecommerce[`${action}`]['actionField'] = {
            id: 'random-' + getRandomString(),
            revenue: revenue
        };

    return ga;
};

const transformCustomEvent = (data) => {
    let ga = {
        event: camelCase(data.appName),
        action: camelCase(data.action)
    };
    if (Array.isArray(data.items)) {
        data.items.forEach((item) => {
            ga[camelCase(item.key)] = item.value;
        });
    }

    return ga;
};

const transformVirtualPageview = (data) => {
    let ga = { event: 'virtualPageview' };
    if (Array.isArray(data.items)) {
        data.items.forEach((item) => {
            if (item.key === 'route') ga['type'] = item.value;
        });
    }
    return ga;
};

const transformCartProduct = (data) => {
    let ga = {};

    const product = getKeyValue(data.items, 'product');
    const action = getKeyValue(data.items, 'cartAction');
    const coverages = !product.coverages
        ? ''
        : product.coverages
              .map((c) => c.name)
              .join('|')
              .replace(/-+$/, '');
    const price = getPrice(product.price);

    const productList = [
        {
            id: product.id,
            name: product.name,
            category: product.category ? product.category : '',
            variant: coverages,
            brand: data.brand,
            price: price
        }
    ];

    if (action === 'add') {
        ga = {
            event: 'addToCart',
            ecommerce: {
                currencyCode: 'DKK',
                add: {
                    products: productList
                }
            }
        };
    } else if (action === 'remove') {
        ga = {
            event: 'removeFromCart',
            ecommerce: {
                currencyCode: 'DKK',
                remove: {
                    products: productList
                }
            }
        };
    }
    return ga;
};

const transformViewProduct = (data) => {
    let ga = {};

    const product = getKeyValue(data.items, 'product');
    if (product) {
        const price = getPrice(product.price);
        const coverages = !product.coverages
            ? ''
            : product.coverages
                  .map((c) => c.name)
                  .join('|')
                  .replace(/-+$/, '');

        ga = {
            event: 'productDetailView',
            ecommerce: {
                currencyCode: 'DKK',
                detail: {
                    products: [
                        {
                            id: product.id,
                            name: product.name,
                            category: product.category ? product.category : '',
                            variant: coverages,
                            brand: data.brand,
                            price: price ? price : ''
                        }
                    ]
                }
            }
        };
    }

    return ga;
};

export default gaDatalayer;
export { validate, transform };
