import enritch from '../utils/enritchDepricated';
import getKeyValue from '../utils/getKeyValue';
import getPrice from '../utils/getPrice';
import { getSessionUserCartId } from '../utils/sessionId';

const sas360 = (data, log) => {
    // validate
    if (!validate(data)) {
        throw new Error('could not validate data');
    }

    // enritch
    const enritchedData = enritch(data);

    // transform
    const sas = transform(enritchedData);

    // consume - retry 33 times (10 sec.) if sas is not yet loaded
    if (sas) delaySas(sas, log, 33);
};

// if sas is not yet loaded, try again later
const delaySas = (sas, log, count) => {
    if (window.ci360) {
        window.ci360('send', sas);
    } else {
        if (count > 0) {
            setTimeout(function () {
                delaySas(sas, log, count--);
            }, 300);
        } else {
            log.warn('sas logging cancled, sas not loaded after 10 sec.');
        }
    }
};

const validate = (data) => {
    if (typeof data !== 'object') return false;
    if (typeof data.appName === 'undefined') return false;
    if (typeof data.action === 'undefined') return false;
    return true;
};

const detectType = (value) => {
    if (typeof value === 'number') return 'num';
    else return 'str';
};

const transform = (data) => {
    if (data.action === 'app-navigate') return transformVirtualPageView(data);
    if (data.action === 'app-ecommerce-view') return transformViewProduct(data);
    if (data.action === 'app-ecommerce-cart') return transformCart(data);
    if (data.action === 'app-ecommerce-checkout') return transformCheckout(data);
    if (data.action === 'app-ecommerce-purchase') return transformCheckout(data);
    if (data.action === 'app-form') return transformFormSubmit(data);
    if (data.action === 'app-ready') return false;

    return transformCusomData(data);
};

const transformCusomData = (data) => {
    let sas = {
        eventName: 'custom',
        apiEventKey: 'appInteractions',

        tdk_type: data.action,
        tdk_appName: data.appName,
        tdk_label: data.label,
        tdk_appVersion: data.appVersion,
        tdk_userSession: data.userSession,
        tdk_url: data.url
    };
    if (Array.isArray(data.items)) {
        let i = 1;
        data.items.forEach((item) => {
            const type = detectType(item.value);
            if (i < 11) {
                sas['tdk_key' + i] = type + '-' + item.key;
                sas['tdk_value' + i] = item.value;
            }
            i++;
        });

        while (i < 11) {
            sas['tdk_key' + i] = '';
            sas['tdk_value' + i] = '';
            i++;
        }
    }

    return sas;
};

const transformCheckout = (data) => {
    const raw = getKeyValue(data.items, 'products');
    const oid = getKeyValue(data.items, 'orderId');
    let action,
        apiKey = null;
    let totalPrice = 0;
    let paymentFrequency = null;
    let products = raw.map((product) => {
        let prod = parseProduct(product, true);
        totalPrice += Number(getPrice(product.price) + ''.replace(/./g, '').replace(/,/g, '.'));
        prod.unitPrice = getPrice(product.price).toLocaleString('da-DK');
        if (product.priceDescr) paymentFrequency = product.priceDescr;
        return prod;
    });

    if (data.action === 'app-ecommerce-purchase') {
        apiKey = 'apiPurchaseView';
        action = 'purchase';
    } else if (data.action === 'app-ecommerce-checkout') {
        apiKey = 'apiCheckoutView';
        action = 'checkout';
    }

    let event = {
        eventName: 'cart',
        action: action,
        apiEventKey: apiKey,
        id: getSessionUserCartId(),
        totalCartValue: totalPrice.toLocaleString('da-DK'),
        items: products
    };

    if (oid) event.orderId = oid;
    if (paymentFrequency) event.paymentType = paymentFrequency;

    return event;
};

const transformCart = (data) => {
    const product = getKeyValue(data.items, 'product');
    const sas = parseProduct(product, true);
    sas['eventName'] = 'cartaction';
    sas['apiEventKey'] = 'apiCartAction';
    sas['group'] = product.group;
    sas['productName'] = product.name;
    sas['productId'] = product.id;
    if (Array.isArray(product.price)) sas['unitPrice'] = product.price[0].toLocaleString('da-DK');
    else sas['unitPrice'] = product.price.toLocaleString('da-DK');

    if (getKeyValue(data.items, 'cartAction', 'add')) {
        sas['cartId'] = getSessionUserCartId();
        sas['action'] = 'add';
    } else if (getKeyValue(data.items, 'cartAction', 'remove')) {
        sas['cartId'] = getSessionUserCartId();
        sas['action'] = 'remove';
    } else {
        console.log('not an action');
    }

    return sas;
};

const transformViewProduct = (data) => {
    let sas = {};
    const item = getKeyValue(data.items, 'product');
    if (item) {
        sas = parseProduct(item, true);
        sas['eventName'] = 'productView';
        sas['apiEventKey'] = 'productView';
    }

    return sas;
};

const transformVirtualPageView = (data) => {
    let sas = { eventName: 'load', url: data.url };
    if (Array.isArray(data.items)) {
        data.items.forEach((item) => {
            if (item.key === 'route') sas['uri'] = item.value;
            if (item.key === 'pageTitle') sas['pageTitle'] = item.value;
        });
    }
    return sas;
};

const parseProduct = (item, price2dk = false) => {
    let sas = {};
    sas['excess'] = item.excess;
    sas['group'] = item.group;
    sas['id'] = item.id;
    sas['name'] = item.name;

    const prices = Array.isArray(item.price) ? item.price : [item.price];
    let i = 1;
    for (let key in prices) {
        sas['price' + i++] = price2dk ? prices[key].toLocaleString('da-DK') : prices[key];
    }

    const coverages = item.coverages;
    i = 1;
    for (let key in coverages) {
        sas['coverageKey' + i] = detectType(coverages[key].value) + '-' + coverages[key].name;
        sas['coverageValue' + i++] = coverages[key].value;
    }

    const coverageSums = item.coverageSums;
    i = 1;
    for (let key in coverageSums) {
        sas['sumKey' + i] = detectType(coverageSums[key].value) + '-' + coverageSums[key].name;
        sas['sumValue' + i++] = coverageSums[key].value;
    }

    const criteria = item.criteria;
    i = 1;
    for (let key in criteria) {
        sas['criteriaKey' + i] = detectType(criteria[key].value) + '-' + criteria[key].name;
        sas['criteriaValue' + i++] = criteria[key].value;
    }

    return sas;
};

const transformFormSubmit = (data) => {
    const formId = getKeyValue(data.items, 'id');
    const formName = getKeyValue(data.items, 'name');
    const itemFields = getKeyValue(data.items, 'fields');
    const fields = itemFields.map((item) => {
        const f = { id: item.id, value: item.value, changes: 1 };
        f.name = item.name ? item.name : item.id;
        if (item.alt) f.alt = item.alt;
        if (item.obscure) f.obscure = true;
        if (item.changes) f.changes = item.changes;
        return f;
    });

    const event = {
        eventName: 'submit',
        id: formId,
        name: formName ? formName : formId,
        fields
    };
    return event;
};

export default sas360;
export { validate, transform };
