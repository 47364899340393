/**
 * Test if a specified value exists at the given key
 * If no value given, you get the value of the specified key - or false
 * @param {Array} items
 * @param {string} key
 * @param {any} value
 */
const getKeyValue = (items, key, value = undefined) => {
    if (key && value) {
        if (items.find((item) => item.key === key && item.value === value) !== undefined) {
            return true;
        } else {
            return false;
        }
    } else if (key && !value) {
        const item = items.find((item) => item.key === key);
        if (item !== undefined) {
            return item.value;
        } else {
            return false;
        }
    }
};

export const setKeyValue = (items, key, value) => {
    const item = items.find((item) => item.key === key);
    if (item) {
        item.value = value;
    }
};

export default getKeyValue;
