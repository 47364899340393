import enritch from '../utils/enritchDepricated';
import validateEnvironment from '../utils/validateEnvironment';
import getPrice from '../utils/getPrice';
import getKeyValue from '../utils/getKeyValue';

/**
 *
 * @param {*} data
 */
function fb(data) {
    // validate
    if (!validate(data)) {
        throw new Error('could not validate data');
    }

    const enritchedData = enritch(data);

    // transform
    const eventData = transform(enritchedData);

    // consume
    if (validateEnvironment() && eventData) {
        if (window.fbq) {
            window.fbq('track', eventData.type, eventData.content);
        }
    }
}

const validate = (data) => {
    if (typeof data !== 'object') return false;
    if (typeof data.appName === 'undefined') return false;
    if (typeof data.action === 'undefined') return false;
    return true;
};

/** we should only track ecommerce - other data should return false */
const transform = (data) => {
    if (data.action === 'app-ecommerce-view') return transformViewProduct(data);
    if (data.action === 'app-ecommerce-cart') return transformAddProduct(data);
    if (data.action === 'app-ecommerce-checkout') return transformCheckout(data);
    if (data.action === 'app-ecommerce-purchase') return transformCheckout(data);

    return false;
};

const transformCheckout = (data) => {
    const products = getKeyValue(data.items, 'products');
    let ids = [],
        contents = [],
        count = 0,
        totalprice = 0,
        category = '',
        priceDescr = null;
    products.map((product) => {
        category = product.category ? product.category : category;
        ids.push(product.id);
        contents.push(product.name);
        totalprice += getPrice(product.price);
        count++;
        if (product.priceDescr) priceDescr = product.priceDescr;
        return null;
    });

    const event = {
        content: {
            content_category: category,
            content_ids: ids.join(','),
            contents: contents.join(','),
            currency: 'DKK',
            num_items: count,
            value: totalprice
        }
    };
    if (data.action === 'app-ecommerce-checkout') event.type = 'InitiateCheckout';
    if (data.action === 'app-ecommerce-purchase') {
        event.type = 'Purchase';
        event.content.payment_type = priceDescr;
    }
    return event;
};

const transformAddProduct = (data) => {
    let eventData = {};
    const product = getKeyValue(data.items, 'product');
    const price = getPrice(product.price);
    const coverages = !product.coverages
        ? ''
        : product.coverages
              .map((c) => c.name)
              .join('-')
              .replace(/-+$/, '');

    if (getKeyValue(data.items, 'cartAction') === 'add') {
        eventData.type = 'AddToCart';

        eventData.content = {
            content_ids: product.id,
            content_category: product.category ? product.category : '',
            content_name: product.name,
            content_type: product.group,
            contents: coverages,
            currency: 'DKK',
            value: price ? price : ''
        };
    } else {
        return false; // not a supported action on facebook
    }

    return eventData;
};

const transformViewProduct = (data) => {
    let eventData = {};

    const product = getKeyValue(data.items, 'product');
    if (product) {
        const coverages = !product.coverages
            ? ''
            : product.coverages
                  .map((c) => c.name)
                  .join('-')
                  .replace(/-+$/, '');

        eventData = {
            type: 'ViewContent',
            content: {
                content_ids: product.id,
                content_category: product.category ? product.category : '',
                content_name: product.name,
                content_type: product.group,
                contents: coverages,
                currency: 'DKK',
                value: getPrice(product.price)
            }
        };
    }

    return eventData;
};

export default fb;
export { validate };
