import * as Cookies from 'js-cookie';

const enritch = (data) => {
    if (!('url' in data)) data['url'] = window.location.href;

    // session set by hippo - only working on current browser session
    if (!('session' in data)) data['session'] = Cookies.get('JSESSIONID');

    // session from Hippo for personalization - stored across browser sessions (only if user accept marketing-cookies)
    if (!('userSession ' in data)) data['userSession '] = Cookies.get('_visitor');

    return data;
};

export default enritch;
