import enritch from '../utils/enritch';
import validateEnvironment from '../utils/validateEnvironment';

// tslint:disable-next-line:no-var-requires
const pkg = require('../../package.json');

/**
 *
 * @param {*} data
 * @param {*} logger
 */
const kibana = (data: any, log: any) => {
	// validate
	if (!validate(data)) {
		throw new Error('could not validate data');
	}

	const enritchedData = enritch(data);

	// transform
	const eventData = transform(enritchedData);

	// consume
	if (validateEnvironment()) {
		const api = (window as any).topContext.config.getEndpointConfig().apiAwsHost + '/bi-tracking/v1/track/';
		const trackingEvent = {
			message: JSON.stringify(eventData),
			appname: data.appName,
			category: eventData.data.action
		};
		postData(api, trackingEvent).catch((err) => {
			log.warn(err);
		});
	}
};

const validate = (data: any) => {
	if (typeof data !== 'object') return false;
	if (typeof data.appName === 'undefined') return false;
	if (typeof data.action === 'undefined') return false;
	return true;
};

const transform = (data: any) => {
	let eventData = {
		app: {
			name: data.appName,
			version: data.appVersion,
			frameworkVersion: data.formatVersion
			// todo: session: ...
			// todo: deploy time?
		},
		meta: {
			url: data.url,
			type: data.type[0],
			source: 'web', // service
			brand: (window as any).topContext.config.getSiteConfig().brandAbbrev
		},
		user: {
			session: data.userSession,
			visitor: data.tdkVisitor
			// cno: customer number
		},
		data: {
			items: {},
			action: data.action, //?
			label: data.label
		},
		subscriber: {
			version: pkg.version
			// todo: session: ...
			// todo: deploy time?
		}
	};

	if (data.action === 'app-ecommerce-view') return transformEcommerse(data, eventData);
	if (data.action === 'app-ecommerce-cart') return transformEcommerse(data, eventData);
	if (data.action === 'app-ecommerce-checkout') return transformEcommerseList(data, eventData);
	if (data.action === 'app-ecommerce-purchase') return transformEcommerseList(data, eventData);
	if (data.action === 'app-form') return transformFormSubmit(data, eventData);
	if (data.action === 'app-step') return transformStep(data, eventData);

	return transformCustomData(data, eventData);
};

const keyify = (item: any) => {
	let key = item.name;
	key = key.toLowerCase().trim().replace(/[\W_]+/g, ' ').trim().replace(/ /g, '_');

	return key.trim();
};

/**
 * ensure variable types (ex coverages) are specified to avoide type clash on kibana index
 * @param {*} product
 */
const transformProductAttributes = (product: any) => {
	const prod = { ...product };

	delete prod.coverages;
	delete prod.coverageSums;
	delete prod.criteria;

	if (product.coverages) {
		prod.coverages = {};
		product.coverages.map((item: any) => (prod.coverages[keyify(item)] = item.value));
	}
	if (product.coverageSums) {
		prod.coveragesums = {};
		product.coverageSums.map((item: any) => (prod.coveragesums[keyify(item)] = item.value));
	}
	if (product.criteria) {
		prod.criteria = {};
		product.criteria.map((item: any) => (prod.criteria[keyify(item)] = item.value));
	}

	return prod;
};

/**
 *
 * @param {*} data
 * @param {*} eventData
 */
const transformEcommerse = (data: any, eventData: any) => {
	if (Array.isArray(data.items)) {
		data.items.forEach((item: any) => {
			// const thing: { [k: string]: any } = {};
			// if (item.key === 'product') {
			//     thing[item.key] = transformProductAttributes(item.value);
			// } else {
			//     thing[item.key] = item.value;
			// }
			// eventData.data.items.push(thing);
			if (item.key === 'product') {
				eventData.data.items[item.key] = transformProductAttributes(item.value);
			} else {
				eventData.data.items[item.key] = item.value;
			}
		});
	}
	return eventData;
};

/**
 *
 * @param {*} data
 * @param {*} eventData
 */
const transformEcommerseList = (data: any, eventData: any) => {
	if (Array.isArray(data.items)) {
		data.items.forEach((item: any) => {
			// const thing: { [k: string]: any } = {};
			// if (item.key === 'products') {
			//     const productList: any = [];
			//     item.value.forEach((val: any) => {
			//         let dt = transformProductAttributes(val);
			//         productList.push(dt);
			//     });
			//     thing[item.key] = productList;
			// } else {
			//     thing[item.key] = item.value;
			// }
			// eventData.data.items.push(thing);
			if (item.key === 'products') {
				const productList: any = [];
				item.value.forEach((val: any) => {
					let dt = transformProductAttributes(val);
					productList.push(dt);
				});
				eventData.data.items[item.key] = productList;
			} else {
				eventData.data.items[item.key] = item.value;
			}
		});
	}
	return eventData;
};

/**
 * wrap all behaviour tracking
 * @param {*} data
 * @param {*} eventData
 */
const transformCustomData = (data: any, eventData: any) => {
	if (Array.isArray(data.items)) {
		data.items.forEach((item: any) => {
			// const thing: { [k: string]: any } = {};
			// thing[item.key] = item.value;
			// eventData.data.items.push(thing);
			eventData.data.items[item.key] = item.value;
		});
	}
	return eventData;
};

/**
 * handle form tracking - ensure types are specified to avoide type clash on kibana index
 * @param {*} data
 * @param {*} eventData
 */
const transformFormSubmit = (data: any, eventData: any) => {
	if (Array.isArray(data.items)) {
		data.items.forEach((item: any) => {
			// const thing: { [k: string]: any } = {};

			// if (item.key === 'id') thing['formid'] = item.value;
			// if (item.key === 'fields') {
			//     thing.fields = [];
			//     item.value.map((field: any) => {
			//         const f = { ...field };
			//         f.value = field.value.toString();
			//         thing.fields.push(f);
			//         return null;
			//     });
			// }

			// eventData.data.items.push(thing);
			if (item.key === 'id') eventData.data.items['formid'] = item.value;
			if (item.key === 'fields') {
				eventData.data.items.fields = [];
				item.value.map((field: any) => {
					const f = { ...field };
					f.value = field.value.toString();
					eventData.data.items.fields.push(f);
					return null;
				});
			}
		});
	}
	return eventData;
};

/**
 * step functions can contain both nubers and strings on the "current" step value. Handle to not fail on kibana index
 * @param {*} data
 * @param {*} eventData
 */
const transformStep = (data: any, eventData: any) => {
	if (Array.isArray(data.items)) {
		data.items.forEach((item: any) => {
			// const thing: { [k: string]: any } = {};
			// if (item.key === 'current') {
			//     thing[item.key] = item.value;
			// } else {
			//     thing[item.key] = item.value;
			// }

			// eventData.data.items.push(thing);
			if (item.key === 'current') {
				eventData.data.items[item.key] = item.value;
			} else {
				eventData.data.items[item.key] = item.value;
			}
		});
	}
	return eventData;
};

async function postData(url = '', data = {}) {
	const response = await fetch(url, {
		method: 'POST',
		mode: 'cors',
		cache: 'no-cache',
		credentials: 'same-origin',
		headers: { 'Content-Type': 'application/json' },
		redirect: 'follow',
		referrerPolicy: 'no-referrer',
		body: JSON.stringify(data)
	});
	return response.json();
}

export default kibana;
export { kibana, validate, transform, keyify };
