const output = (data) => {
    // validate

    // transform

    // consume
    console.log(data);
};

export default output;
