/** get a unique session key for the user */
const getSessionUserCartId = () => {
    if (window.sessionStorage) {
        let id = window.sessionStorage.getItem('uniqueCartId');
        if (!id) {
            id = getRandomString();
            window.sessionStorage.setItem('uniqueCartId', id);
        }
        return id;
    }
};

const getRandomString = () => {
    return Math.random().toString(36).substring(2);
};

export { getSessionUserCartId, getRandomString };
