/**
 *
 * @param {Array|number} price
 */
const getPrice = (price) => {
    if (Array.isArray(price)) return price[0];
    if (typeof price === 'number') return price;

    return '';
};

export default getPrice;
